/**
 * @generated SignedSource<<570831a3847ec6e306fb55c3e76aec85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileSidebar_UserProfile_Query$variables = Record<PropertyKey, never>;
export type UserProfileSidebar_UserProfile_Query$data = {
  readonly userContext: {
    readonly teams: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"UserProfileTeamsTable_Fragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserEmailNotificationsSettings_Fragment">;
};
export type UserProfileSidebar_UserProfile_Query = {
  response: UserProfileSidebar_UserProfile_Query$data;
  variables: UserProfileSidebar_UserProfile_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileSidebar_UserProfile_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContext",
        "kind": "LinkedField",
        "name": "userContext",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "teams",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserProfileTeamsTable_Fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserEmailNotificationsSettings_Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserProfileSidebar_UserProfile_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContext",
        "kind": "LinkedField",
        "name": "userContext",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "teams",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "icon",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "experimentCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metricCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailNotificationSettings",
        "kind": "LinkedField",
        "name": "userNotificationEmailSettings",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailNotificationSettingsOptions",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onExperimentDataProblem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onExperimentStatusChange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onSequentialExperimentMetricStatisticalSignificance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onTeamMetricStatisticalSignificance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onTeamFeatureFlagEnableDisable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onTeamFeatureFlagInProdAllocationUpdate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba9c3c087318ea6e7d370016f0d17a93",
    "id": null,
    "metadata": {},
    "name": "UserProfileSidebar_UserProfile_Query",
    "operationKind": "query",
    "text": "query UserProfileSidebar_UserProfile_Query {\n  userContext {\n    teams {\n      ...UserProfileTeamsTable_Fragment\n      id\n    }\n  }\n  ...UserEmailNotificationsSettings_Fragment\n}\n\nfragment UserEmailNotificationsSettings_Fragment on Query {\n  userNotificationEmailSettings {\n    id\n    enabled\n    settings {\n      onExperimentDataProblem\n      onExperimentStatusChange\n      onSequentialExperimentMetricStatisticalSignificance\n      onTeamMetricStatisticalSignificance\n      onTeamFeatureFlagEnableDisable\n      onTeamFeatureFlagInProdAllocationUpdate\n    }\n  }\n}\n\nfragment UserProfileTeamsTable_Fragment on Team {\n  id\n  icon\n  name\n  experimentCount\n  metricCount\n}\n"
  }
};
})();

(node as any).hash = "d4ced483ff8566ab844bf52f86385e03";

export default node;
