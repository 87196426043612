/**
 * @generated SignedSource<<acf2db8f6dd21680895dedbca416512e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SearchBar_Query$variables = Record<PropertyKey, never>;
export type SearchBar_Query$data = {
  readonly assignmentSources: ReadonlyArray<{
    readonly dimensions: ReadonlyArray<{
      readonly dimensionName: string;
      readonly id: string;
    }>;
    readonly id: string;
    readonly name: string | null;
  }>;
  readonly entityDimensionSources: ReadonlyArray<{
    readonly dimensions: ReadonlyArray<{
      readonly dimensionName: string;
      readonly id: string;
    }>;
    readonly id: string;
    readonly name: string;
  }>;
  readonly metricEvents: ReadonlyArray<{
    readonly id: string;
    readonly metricEventMeasures: ReadonlyArray<{
      readonly id: string;
      readonly measureName: string;
    }>;
    readonly name: string | null;
  }>;
  readonly metrics: ReadonlyArray<{
    readonly entity: {
      readonly id: string;
      readonly name: string;
    };
    readonly id: string;
    readonly name: string;
  }>;
};
export type SearchBar_Query = {
  response: SearchBar_Query$data;
  variables: SearchBar_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Dimension",
    "kind": "LinkedField",
    "name": "dimensions",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dimensionName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Metric",
    "kind": "LinkedField",
    "name": "metrics",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Entity",
        "kind": "LinkedField",
        "name": "entity",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AssignmentSource",
    "kind": "LinkedField",
    "name": "assignmentSources",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MetricEventSource",
    "kind": "LinkedField",
    "name": "metricEvents",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MetricEventMeasure",
        "kind": "LinkedField",
        "name": "metricEventMeasures",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "measureName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "EntityDimensionSource",
    "kind": "LinkedField",
    "name": "entityDimensionSources",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchBar_Query",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SearchBar_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "61c9366df62c4767f56a4a538cf81562",
    "id": null,
    "metadata": {},
    "name": "SearchBar_Query",
    "operationKind": "query",
    "text": "query SearchBar_Query {\n  metrics {\n    id\n    name\n    entity {\n      id\n      name\n    }\n  }\n  assignmentSources {\n    id\n    name\n    dimensions {\n      id\n      dimensionName\n    }\n  }\n  metricEvents {\n    id\n    name\n    metricEventMeasures {\n      id\n      measureName\n    }\n  }\n  entityDimensionSources {\n    id\n    name\n    dimensions {\n      id\n      dimensionName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c24f466647a5b7c4297290fa5a578ca";

export default node;
