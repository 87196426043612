/**
 * @generated SignedSource<<5cbe7dc0c259b7c1eebbed094d4e9a12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileDropdown_Query$variables = {
  userId: number;
};
export type UserProfileDropdown_Query$data = {
  readonly user: {
    readonly firstName: string;
    readonly imageUrl: string | null;
    readonly isDeleted: boolean;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserProfileSidebar_UserProfile_Fragment">;
  };
  readonly userSelectableCompanies: ReadonlyArray<{
    readonly companyId: number;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly name: string;
  }>;
};
export type UserProfileDropdown_Query = {
  response: UserProfileDropdown_Query$data;
  variables: UserProfileDropdown_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDeleted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "userSelectableCompanies",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyId",
      "storageKey": null
    },
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileDropdown_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserProfileSidebar_UserProfile_Fragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfileDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "30c6c9fce15fab2d1b7039303232f503",
    "id": null,
    "metadata": {},
    "name": "UserProfileDropdown_Query",
    "operationKind": "query",
    "text": "query UserProfileDropdown_Query(\n  $userId: Int!\n) {\n  user(userId: $userId) {\n    name\n    firstName\n    imageUrl\n    isDeleted\n    ...UserProfileSidebar_UserProfile_Fragment\n    id\n  }\n  userSelectableCompanies {\n    id\n    companyId\n    name\n    imageUrl\n  }\n}\n\nfragment UserProfileSidebar_UserProfile_Fragment on User {\n  name\n  email\n  imageUrl\n  role\n}\n"
  }
};
})();

(node as any).hash = "36f644f7c50bba61f0e4445ca9902b4e";

export default node;
