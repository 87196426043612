import { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
// @note remove this when bootstrap is not needed
import 'bootstrap/dist/css/bootstrap.min.css';
import { AUTHENTICATION_PATHNAME } from 'middleware';
import NextApp, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { CookiesProvider } from 'react-cookie';
import Modal from 'react-modal';
import { RelayEnvironmentProvider, fetchQuery } from 'react-relay/hooks';
import { ToastProvider } from 'react-toast-notifications';
import { ErrorBoundary } from 'components/ErrorBoundary';
import EppoFeatureFlagProvider from 'components/feature-flag/EppoFeatureFlagProvider';
import { IntercomProvider } from 'components/intercom/IntercomProvider';
import MenuOptionsSidebar from 'components/layout/MenuOptionsSidebar';
import { SidebarContainer } from 'components/layout/nav/styled';
import { AuthenticatedPageLayoutContainer } from 'components/layout/styled';
import { PageLoader } from 'components/loaders/PageLoader';
import LoginPage from 'components/login/LoginPage';
import { RbacPermissionsProvider } from 'components/RbacPermissionsProvider';
import MazeScript from 'components/scripts/MazeScript';
import { useCommandBar } from 'components/search-bar/helpers/command-bar-helper';
import { StoredDataProvider } from 'components/StoredDataProvider';
import { authorizeUserQuery } from 'lib/__generated__/authorizeUserQuery.graphql';
import { AUTH_QUERY } from 'lib/authorize-user-query';
import makeEnvironment from 'lib/relay';
import { GlobalContext, useValue } from 'lib/state';
import { IUserContext, UserContext, UserInfo } from 'lib/user-context';
import { initDatadogRum } from 'helpers/datadog-helper';
import initializeThirdPartyTools from 'helpers/initializeThirdPartyTools';
import { page } from 'helpers/rudderstack-helper';
import { CustomToastContainer } from 'hooks/useCustomToastContainer';
import { useNavCollapsed } from 'hooks/useNavCollapsed';
import '../styles/fonts.css';
import GlobalStyle from 'styles/GlobalStyle';
import { useEventTracking } from 'components/feature-flag/hooks/useEventTracking';
Modal.setAppElement('#__next');
initDatadogRum();
interface IOfflineInitAppProps extends AppProps {
  precomputedConfiguration: string;
}
function App({
  Component,
  pageProps,
  router,
  precomputedConfiguration
}: IOfflineInitAppProps): JSX.Element {
  const [state, dispatch] = useValue();
  const relayEnvironment = makeEnvironment();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore maintain behavior by using an empty userInfo before logged in/loaded
  const [userInfo, setUserInfo] = useState<UserInfo>({});
  const pathname = router.pathname;
  const isLoginPage = pathname === AUTHENTICATION_PATHNAME;
  const isJoinTeamsPage = pathname === '/join-teams';
  const [isLoading, setIsLoading] = useState(!isLoginPage && !isJoinTeamsPage);
  const trackEvent = useEventTracking();
  const reloadUserContext = useCallback(async () => {
    try {
      const res = await fetchQuery<authorizeUserQuery>(relayEnvironment, AUTH_QUERY, {}).toPromise();
      const respUser = res?.userContext ?? null;
      if (respUser?.userId) {
        setUserInfo(respUser);
      }
    } catch (error) {
      console.error('Auth: Error fetching userContext.', {
        error
      });
    }
  }, [relayEnvironment]);
  const userContext = useMemo<IUserContext>(() => ({
    ...userInfo,
    reloadUserContext
  }), [userInfo, reloadUserContext]);
  useEffect(() => {
    if (isLoginPage) return;
    async function updateUser() {
      await reloadUserContext();
      setIsLoading(false);
    }
    updateUser().catch(console.error);
  }, [reloadUserContext, isLoginPage, isJoinTeamsPage]);
  const commandBarOrgId = process.env.NEXT_PUBLIC_COMMANDBAR_ORG_ID ?? '';
  useCommandBar({
    org: commandBarOrgId,
    userId: `${userInfo?.userId}`
  });
  useEffect(() => {
    function handleRouteChange() {
      trackEvent('page_view', {
        path: pathname
      });
      page();
    }
    if (window.CommandBar) {
      window.CommandBar.addRouter((url: string): void => {
        router.push(url);
      });
    }
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  });
  useEffect(() => {
    if (userInfo?.userId) {
      initializeThirdPartyTools(userInfo.userId, relayEnvironment).catch(console.error);
    }
  }, [userInfo, relayEnvironment]);
  const {
    NavCollapsedContext,
    collapsed,
    toggleCollapsed
  } = useNavCollapsed();
  let pageBody;
  if (isLoading) {
    pageBody = <PageLoader />;
  } else if (!userInfo?.userId) {
    pageBody = <LoginPage />;
  } else {
    pageBody = <UserContext.Provider value={userContext}>
        <EppoFeatureFlagProvider precomputedConfiguration={precomputedConfiguration}>
          <RbacPermissionsProvider>
            <IntercomProvider>
              <ToastProvider placement="bottom-center" components={{
              ToastContainer: CustomToastContainer
            }}>
                {router.isReady && isJoinTeamsPage ? <Component {...pageProps} /> : <AuthenticatedPageLayoutContainer data-cy-element-type="auth-page-layout">
                    <NavCollapsedContext.Provider value={{
                  collapsed,
                  toggleCollapsed
                }}>
                      <SidebarContainer collapsed={collapsed}>
                        <MenuOptionsSidebar />
                      </SidebarContainer>
                      <Component {...pageProps} />
                    </NavCollapsedContext.Provider>
                  </AuthenticatedPageLayoutContainer>}
              </ToastProvider>
            </IntercomProvider>
          </RbacPermissionsProvider>
        </EppoFeatureFlagProvider>
      </UserContext.Provider>;
  }
  return <ErrorBoundary>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <StoredDataProvider>
          <GlobalContext.Provider value={[state, dispatch]}>
            <CookiesProvider>
              <Head>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <link rel="shortcut icon" href="/favicon.svg" />
              </Head>
              {/* load scripts here */}
              <MazeScript />
              <GlobalStyle />

              {pageBody}
            </CookiesProvider>
          </GlobalContext.Provider>
        </StoredDataProvider>
      </RelayEnvironmentProvider>
    </ErrorBoundary>;
}
App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  let precomputedConfiguration = '{}';
  if (appContext.ctx.req?.headers.cookie) {
    try {
      /*
      The cookie contains an encrypted jwt token generated by the auth0 server, and we use the same token to access the 
      precomputed-configuration endpoint. The reason we can't generate the precomputed configuration here is because we need 
      the subject's key and attributes to generate it. And we can't decrypt the jwt to extract subject information here, because
      that would mean passing the encryption secret to the frontend app, which would weaken security. The client side rendered 
      app uses a relay query to get the subject information, which we can use in this "server side rendered" process.
      */
      const response = await fetch(`${process.env.API_BASE_URL}/precomputed-configuration`, {
        credentials: 'include',
        headers: {
          Cookie: appContext.ctx.req.headers.cookie
        }
      });
      const result = await response.json();
      precomputedConfiguration = result.precomputedConfiguration;
    } catch (error) {
      console.error('Failed to refresh configuration wire:', error);
    }
  }
  return {
    ...appProps,
    precomputedConfiguration
  };
};
export default App;