/**
 * @generated SignedSource<<603f4fc3300f412957ffd62745f68fd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserEmailNotificationsSettings_Fragment$data = {
  readonly userNotificationEmailSettings: {
    readonly enabled: boolean;
    readonly id: string;
    readonly settings: {
      readonly onExperimentDataProblem: boolean;
      readonly onExperimentStatusChange: boolean | null;
      readonly onSequentialExperimentMetricStatisticalSignificance: boolean | null;
      readonly onTeamFeatureFlagEnableDisable: boolean | null;
      readonly onTeamFeatureFlagInProdAllocationUpdate: boolean | null;
      readonly onTeamMetricStatisticalSignificance: boolean | null;
    };
  };
  readonly " $fragmentType": "UserEmailNotificationsSettings_Fragment";
};
export type UserEmailNotificationsSettings_Fragment$key = {
  readonly " $data"?: UserEmailNotificationsSettings_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserEmailNotificationsSettings_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserEmailNotificationsSettings_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailNotificationSettings",
      "kind": "LinkedField",
      "name": "userNotificationEmailSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailNotificationSettingsOptions",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onExperimentDataProblem",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onExperimentStatusChange",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onSequentialExperimentMetricStatisticalSignificance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onTeamMetricStatisticalSignificance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onTeamFeatureFlagEnableDisable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onTeamFeatureFlagInProdAllocationUpdate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5db790d0ce9fc749e23d1045408930a9";

export default node;
