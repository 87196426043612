import { useContext } from 'react';
import { IUserContext, UserContext } from 'lib/user-context';
export function useUser(): IUserContext {
  const user = useContext(UserContext);
  if (!user) {
    // Should not get to this point because _app.tsx redirects to / if no user is present.
    throw new Error('Expected authenticated user but none was found.');
  }
  return user;
}
export function useUserIfLoaded(): IUserContext | null {
  return useContext(UserContext);
}