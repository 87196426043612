import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { NO_URL } from 'components/layout/MenuOptionsSidebar';
import EppoLogo from 'components/logo/EppoLogo';
import { FlexBox } from 'components/style-components/FlexBox';
import Icon from 'components/ui/icon/Icon';
import UserProfileDropdown from 'components/user-profile-dropdown/UserProfileDropdown';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import SidebarOption from './SidebarOption';
import { SidebarWrapper, SidebarToggleButton } from './styled';
interface ISidebarProp {
  options: ISideMenuOption[];
  bottomOptions?: ISideMenuOption[];
  updatesHighlightStrategy?: string;
  updatesClickHandler?: () => void;
}
export interface ISideMenuOption {
  name: string;
  url: string;
  forceNewTab?: boolean;
  subOptions?: ISideMenuSubOption[];
}
export interface ISideMenuSubOption extends Omit<ISideMenuOption, 'subOptions'> {
  parentUrl: string;
}
export default function Sidebar({
  options,
  bottomOptions,
  // Below props are used for the dogfood bandit that highlights when there are new updates
  updatesHighlightStrategy,
  updatesClickHandler
}: ISidebarProp) {
  const [hoveredOption, setHoveredOption] = useState('');
  const {
    collapsed,
    toggleCollapsed
  } = useContext(NavCollapsedContext);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedParent, setSelectedParent] = useState<string | undefined>(undefined);
  const router = useRouter();
  useEffect(() => {
    const afterChange = (route: string) => {
      setSelectedOption(route);
    };
    router.events.on('routeChangeComplete', afterChange);
    return () => {
      router.events.off('routeChangeComplete', afterChange);
    };
  }, [router, setSelectedOption, collapsed]);
  return <SidebarWrapper onMouseLeave={() => setHoveredOption('')}>
      <FlexBox justifyContent={collapsed ? 'center' : 'space-between'} alignItems="flex-start" padding="4px 0 0" height="32px">
        {!collapsed && <FlexBox padding="0 0 0 4px">
            <EppoLogo color="light" height="28px" />
          </FlexBox>}
        <SidebarToggleButton onClick={toggleCollapsed}>
          <Icon name="First page arrow.first_page" style={{
          transform: collapsed ? 'rotate(180deg)' : undefined
        }} />
        </SidebarToggleButton>
      </FlexBox>

      <FlexBox flex={1} flexDirection="column" gap="24px" margin="40px 0 0">
        {options.map(({
        name,
        url,
        subOptions
      }: ISideMenuOption) => <SidebarOption key={url} url={url} additionalClickHandler={e => {
        if (url === NO_URL) e.preventDefault();
      }} name={name} subOptions={subOptions} setIsHovered={isHovered => setHoveredOption(isHovered ? name : '')} isHovered={hoveredOption === name} selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectedParent={selectedParent} setSelectedParent={setSelectedParent} />)}
      </FlexBox>

      <UserProfileDropdown />

      {!!bottomOptions?.length && <FlexBox flexDirection="column" gap="16px" margin="16px 0 0">
          {bottomOptions.map(({
        name,
        url,
        forceNewTab
      }) => {
        let highlightStrategy = undefined;
        if (name.toLowerCase() === 'updates' && updatesHighlightStrategy) {
          highlightStrategy = updatesHighlightStrategy;
        }
        return <SidebarOption key={url} url={url} name={name} forceNewTab={forceNewTab} highlightStrategy={highlightStrategy} additionalClickHandler={updatesClickHandler} setIsHovered={isHovered => setHoveredOption(isHovered ? name : '')} isHovered={hoveredOption === name} selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectedParent={selectedParent} setSelectedParent={setSelectedParent} />;
      })}
        </FlexBox>}
    </SidebarWrapper>;
}