/**
 * @generated SignedSource<<fcf4237089b7d7f606bb0242c9b9e0c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type initializeThirdPartyTools_User_Query$variables = {
  userId: number;
};
export type initializeThirdPartyTools_User_Query$data = {
  readonly user: {
    readonly createdAt: string;
    readonly email: string;
    readonly id: string;
    readonly name: string;
    readonly userCompanyRelations: ReadonlyArray<{
      readonly company: {
        readonly companyId: number;
        readonly createdAt: string;
        readonly id: string;
        readonly name: string;
        readonly primaryDomain: {
          readonly domain: string;
        } | null;
      };
    }>;
    readonly userId: number;
  } | null;
};
export type initializeThirdPartyTools_User_Query = {
  response: initializeThirdPartyTools_User_Query$data;
  variables: initializeThirdPartyTools_User_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "initializeThirdPartyTools_User_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCompanyRelation",
            "kind": "LinkedField",
            "name": "userCompanyRelations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllowedDomain",
                    "kind": "LinkedField",
                    "name": "primaryDomain",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "initializeThirdPartyTools_User_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCompanyRelation",
            "kind": "LinkedField",
            "name": "userCompanyRelations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllowedDomain",
                    "kind": "LinkedField",
                    "name": "primaryDomain",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "501898e7566d5e72376c404acc339915",
    "id": null,
    "metadata": {},
    "name": "initializeThirdPartyTools_User_Query",
    "operationKind": "query",
    "text": "query initializeThirdPartyTools_User_Query(\n  $userId: Int!\n) {\n  user(userId: $userId) {\n    id\n    userId\n    email\n    name\n    createdAt\n    userCompanyRelations {\n      company {\n        id\n        name\n        companyId\n        primaryDomain {\n          domain\n          id\n        }\n        createdAt\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b098e1ac1f1bd97918910d39783b3fdd";

export default node;
