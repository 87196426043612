import { CSSProperties, useMemo, useRef, Fragment } from 'react';
import { PlacesType } from 'react-tooltip';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { domSafeElementId } from 'helpers/dom-helper';
import { stripMarkdown } from 'helpers/markdown';
import { AddEllipsisDiv } from './styled';
export interface IAddEllipsis {
  width?: number | string;
  text: string;
  noOfLines: number;
  breakWord?: boolean;
  customDelimiter?: string;
  showTooltip?: boolean;
  cyElementType?: string;
  tooltipPlacement?: PlacesType;
  style?: CSSProperties;
  disableMarkdown?: boolean;
}
export function AddEllipsis({
  width = '100%',
  text,
  noOfLines,
  breakWord = false,
  customDelimiter = undefined,
  showTooltip = true,
  tooltipPlacement = 'top',
  style = {},
  disableMarkdown = false
}: IAddEllipsis): JSX.Element {
  const elementId = useMemo(() => domSafeElementId(`ellipsis-tooltip-${Math.floor(Math.random() * 100000)}`), []);
  const divRef = useRef<HTMLDivElement>(null);
  const isEllipsisUsed = !divRef.current ? false : divRef.current.offsetHeight < divRef.current.scrollHeight || divRef.current.offsetWidth < divRef.current.scrollWidth;
  const processedText = disableMarkdown ? stripMarkdown(text) : text;
  return <Tooltip placement={tooltipPlacement} text={text} disabled={!showTooltip || !isEllipsisUsed} id={elementId}>
      <AddEllipsisDiv ref={divRef} width={width} noOfLines={noOfLines} breakWord={breakWord} style={style}>
        {customDelimiter ? processedText.split(customDelimiter).map((part, index, array) => <Fragment key={index}>
                {part}
                {index < array.length - 1 && <>
                    <wbr />
                    {customDelimiter}
                  </>}
              </Fragment>) : processedText}
      </AddEllipsisDiv>
    </Tooltip>;
}