import { useCallback } from 'react';

import { getInstance } from '@eppo/js-client-sdk';

import { useUserIfLoaded } from 'hooks/useUser';

export function useEventTracking() {
  const user = useUserIfLoaded();
  const eppoClient = getInstance();

  /**
   * Logs an event if event logging is enabled.
   */
  const logEvent = useCallback(
    (type: string, payload: Record<string, any>) => {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const eventLoggingEnabled = eppoClient.getBooleanAssignment(
          'dogfood-event-logger',
          'eppo',
          {},
          false,
        );

        if (!eventLoggingEnabled) {
          return;
        }

        if (user) {
          payload = {
            ...payload,
            userId: user.userId,
            email: user.email,
          };
        }

        eppoClient.track(type, payload);
      } catch (error) {}
    },
    [eppoClient, user],
  );

  return logEvent;
}
