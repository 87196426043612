/**
 * @generated SignedSource<<4e2ab1e1fb5dde7b2ac3f02d45d061af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type UserRoleEnum = "Admin" | "DataOwner" | "ExperimentEditor" | "Viewer";
import { FragmentRefs } from "relay-runtime";
export type UserProfileSidebar_UserProfile_Fragment$data = {
  readonly email: string;
  readonly imageUrl: string | null;
  readonly name: string;
  readonly role: UserRoleEnum;
  readonly " $fragmentType": "UserProfileSidebar_UserProfile_Fragment";
};
export type UserProfileSidebar_UserProfile_Fragment$key = {
  readonly " $data"?: UserProfileSidebar_UserProfile_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileSidebar_UserProfile_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfileSidebar_UserProfile_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4e2d8a7eb755cb0913aad7baa92413f6";

export default node;
