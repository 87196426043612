/**
 * @generated SignedSource<<a625a8d3521e7de72906c05995635d3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditUserCompanyInput = {
  companyId: number;
  userId: number;
};
export type UserProfileDropdown_Select_Mutation$variables = {
  userCompanyInput: EditUserCompanyInput;
};
export type UserProfileDropdown_Select_Mutation$data = {
  readonly editUserCompany: {
    readonly id: string;
  };
};
export type UserProfileDropdown_Select_Mutation = {
  response: UserProfileDropdown_Select_Mutation$data;
  variables: UserProfileDropdown_Select_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userCompanyInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userCompanyInput",
        "variableName": "userCompanyInput"
      }
    ],
    "concreteType": "UserCompanyRelation",
    "kind": "LinkedField",
    "name": "editUserCompany",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileDropdown_Select_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfileDropdown_Select_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "76651b820ac3945cb4a47a19a89b587a",
    "id": null,
    "metadata": {},
    "name": "UserProfileDropdown_Select_Mutation",
    "operationKind": "mutation",
    "text": "mutation UserProfileDropdown_Select_Mutation(\n  $userCompanyInput: EditUserCompanyInput!\n) {\n  editUserCompany(userCompanyInput: $userCompanyInput) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e8edc519d9dbd1882067006ea196e14";

export default node;
