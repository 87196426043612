import { useEffect, useMemo, useState } from 'react';
import { EFeatureFlag } from 'constants/featureFlags';
import axios from 'axios';
import { useRouter } from 'next/router';
import { graphql, PreloadedQuery, useMutation, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { DOGFOOD_BANDIT_FUNCTION_URI_ROOT } from 'components/feature-flag/EppoFeatureFlagProvider';
import { useBoolFlagAssignment, usePrecomputedBanditAction, useStringFlagOn } from 'components/feature-flag/hooks/useFlagAssignment';
import { isCompleted } from 'components/getting-started/helpers';
import { Suspense } from 'components/Suspense';
import { getEnvironment, isProductionEnv } from 'helpers/env-helper';
import useRbacPermissions from 'hooks/useRbacPermissions';
import { useUser } from 'hooks/useUser';
import Sidebar, { ISideMenuOption } from './nav/Sidebar';
import { MenuOptionsSidebar_Query, MenuOptionsSidebar_Query$data } from './__generated__/MenuOptionsSidebar_Query.graphql';
import { MenuOptionsSidebarRecordUpdatesClicked_Mutation } from './__generated__/MenuOptionsSidebarRecordUpdatesClicked_Mutation.graphql';
export const NO_URL = '#';
const QUERY = graphql`
  query MenuOptionsSidebar_Query($userId: Int!) {
    userCompany {
      companyId
      name
      isEppoOwned
    }
    companyEnabledFeatures {
      featureFlaggingStatus
    }
    user(userId: $userId) {
      id
      lastClickedUpdates
      createdAt
    }
    experimentationGettingStartedState {
      hasDataWarehouseConnection
      hasAtLeastOneEntity
      hasAtLeastOneAssignmentSQL
      hasAtLeastOneFactSQL
      hasAtLeastOneMetric
      hasAtLeastOneExperiment
      hasAtLeastOneTeam
    }
    randomizationGettingStartedState {
      hasAtLeastOneSDKKey
      hasAtLeastOneFeatureFlag
      hasAtLeastOneFeatureFlagWithAllocation
      hasAtLeastOneFeatureFlagWithStatusEnabled
      hasAtLeastOneUsedSDKKey
      hasAtLeastOneAssignmentSQL
      hasAtLeastOneTeam
    }
  }
`;
const RECORD_UPDATES_CLICKED_MUTATION = graphql`
  mutation MenuOptionsSidebarRecordUpdatesClicked_Mutation($input: UserEventInput!) {
    recordUpdatesClicked(input: $input) {
      success
    }
  }
`;
const DOGFOOD_BANDIT_KEY = 'update-highlights-bandit';
const getMenuOptions = ({
  companyEnabledFeatures = {},
  isAdminEnabled,
  isTeamsEnabled,
  canUpdateDefinitions,
  isGettingStartedEnabled,
  isInsightsEnabled,
  isPipelineVisibilityEnabled,
  isHoldoutsEnabled,
  isAudiencesEnabled,
  isNavigationImprovementsEnabled,
  isProtocolsEnabled
}: {
  companyEnabledFeatures?: Partial<MenuOptionsSidebar_Query$data['companyEnabledFeatures']>;
  isAdminEnabled: boolean;
  isTeamsEnabled: boolean;
  canUpdateDefinitions: boolean;
  isGettingStartedEnabled: boolean;
  isInsightsEnabled: boolean;
  isPipelineVisibilityEnabled: boolean;
  isHoldoutsEnabled: boolean;
  isAudiencesEnabled: boolean;
  isNavigationImprovementsEnabled: boolean;
  isProtocolsEnabled: boolean;
}): ISideMenuOption[] => {
  // this is very meta, but we want to have the feature "feature flag trials" behind a feature flag 😂
  // eslint-disable-next-line
  const isFeatureFlagsTrialsFeatureEnabled = useBoolFlagAssignment(EFeatureFlag.FeatureFlagTrials);
  const {
    featureFlaggingStatus
  } = companyEnabledFeatures;
  const isFeatureFlagsAccessible = featureFlaggingStatus && ['ENABLED', 'TRIAL_ACTIVE', 'TRIAL_ENDED'].includes(featureFlaggingStatus);
  // Is the company eligible to start a feature flags trial?
  const canStartFeatureFlagsTrial = !isFeatureFlagsAccessible && isFeatureFlagsTrialsFeatureEnabled && featureFlaggingStatus === 'TRIAL_ELIGIBLE';
  const isConfigurationOptionVisible = isFeatureFlagsAccessible || canStartFeatureFlagsTrial;
  const featureFlagsPageUrl = '/configuration/feature-flags';
  const trialsPageUrl = '/configuration/feature-flags/trials';
  const featureFlaggingUrl = isFeatureFlagsAccessible ? featureFlagsPageUrl : trialsPageUrl;
  const flagName = isNavigationImprovementsEnabled ? 'Flags' : 'Configuration';
  const configurationSubOptions: ISideMenuOption[] = [{
    name: 'Feature Flags',
    url: featureFlaggingUrl
  }];
  if (isHoldoutsEnabled) {
    configurationSubOptions.push({
      name: 'Holdouts',
      url: '/configuration/holdouts'
    });
  }
  if (isAudiencesEnabled) {
    configurationSubOptions.push({
      name: 'Audiences',
      url: '/configuration/audiences'
    });
  }
  configurationSubOptions.push({
    name: 'Environments',
    url: '/configuration/environments'
  });
  const adminSubOptions: ISideMenuOption[] = [];
  if (isAdminEnabled) {
    adminSubOptions.push({
      name: 'Admin',
      url: '/admin'
    });
  }
  if (isProtocolsEnabled) {
    adminSubOptions.push({
      name: 'Protocols',
      url: '/protocols'
    });
  }
  if (isPipelineVisibilityEnabled) {
    adminSubOptions.push({
      name: 'Warehouse',
      url: '/warehouse'
    });
  }
  if (isTeamsEnabled) {
    adminSubOptions.push({
      name: 'Teams',
      url: '/teams'
    });
  }
  const options: ISideMenuOption[] = [];
  if (isGettingStartedEnabled) {
    options.push({
      name: 'Getting Started',
      url: '/getting-started'
    });
  }
  if (isInsightsEnabled) {
    options.push({
      name: 'Insights',
      url: '/insights'
    });
  }
  options.push({
    name: 'Analysis',
    url: '/experiments'
  });
  if (isConfigurationOptionVisible) {
    const url = '/configuration/feature-flags';
    options.push({
      name: flagName,
      url,
      subOptions: configurationSubOptions.map(o => ({
        ...o,
        parentUrl: url
      }))
    });
  }
  options.push({
    name: 'Metrics',
    url: '/metrics'
  });
  if (canUpdateDefinitions) {
    options.push({
      name: 'Definitions',
      url: '/definitions'
    });
  }
  if (isAdminEnabled || isPipelineVisibilityEnabled || isTeamsEnabled) {
    const url = isAdminEnabled ? '/admin' : NO_URL;
    options.push({
      name: 'Admin',
      url,
      subOptions: adminSubOptions.map(o => ({
        ...o,
        parentUrl: url
      }))
    });
  }
  return options;
};
export default function MenuOptionsSidebar(): JSX.Element {
  const {
    userId
  } = useUser();
  const router = useRouter();
  const [updatesClicked, setUpdatesClicked] = useState(false);
  const [recordUpdateClicked] = useMutation<MenuOptionsSidebarRecordUpdatesClicked_Mutation>(RECORD_UPDATES_CLICKED_MUTATION);
  const isAudiencesEnabled = useStringFlagOn(EFeatureFlag.FFAudiences);
  const isHoldoutsEnabled = useStringFlagOn(EFeatureFlag.HoldoutsEndToEnd);
  const {
    canReadProtocols
  } = useRbacPermissions();
  const isProtocolsEnabled = useBoolFlagAssignment(EFeatureFlag.ProtocolsMilestoneOne) && canReadProtocols;
  const isNavigationImprovementsEnabled = useBoolFlagAssignment(EFeatureFlag.NavigationImprovements);
  const bottomOptions = [{
    url: 'https://updates.eppo.cloud/en',
    name: 'Updates',
    forceNewTab: true
  }, {
    url: 'https://docs.geteppo.com/',
    name: 'Docs'
  }];

  // Note: we will be dogfooding a bandit to optimize how we highlight new updates
  // As part of this, we need additional click handling to record when the user has clicked on updates both the
  // warehouse and the Eppo database
  const recordBanditMetric = async () => {
    const subjectKey = banditSubjectKey(router.query.subjectKey, userId);
    await axios.post(`${DOGFOOD_BANDIT_FUNCTION_URI_ROOT}/record`, {
      subjectKey,
      metricKey: 'click',
      metricValue: 'updates'
    });
  };
  const handleUpdatesClicked = () => {
    // remove any active highlighting
    setUpdatesClicked(true);
    // update most recent timestamp in the Eppo database
    recordUpdateClicked({
      variables: {
        input: {
          id: userId // Use user ID (vs. subjectKey) as this backend mutation is independent of the bandit operation
        }
      }
    });
    // record the bandit metric event
    recordBanditMetric().catch(e => console.warn('Unable to record Updates clicked', e));
  };
  const [queryReference, loadQuery] = useQueryLoader<MenuOptionsSidebar_Query>(QUERY);
  useEffect(() => {
    if (userId) {
      loadQuery({
        userId
      });
    }
  }, [loadQuery, userId]);
  const menuOptions = getMenuOptions({
    isAdminEnabled: false,
    isTeamsEnabled: false,
    canUpdateDefinitions: false,
    isGettingStartedEnabled: false,
    isInsightsEnabled: false,
    isPipelineVisibilityEnabled: false,
    isHoldoutsEnabled,
    isAudiencesEnabled,
    isNavigationImprovementsEnabled,
    isProtocolsEnabled
  });

  // fallback without loading FF enabled menu items
  const fallback = <Sidebar options={menuOptions} bottomOptions={bottomOptions} />;
  return <Suspense fallback={fallback}>
      {queryReference && <MenuOptionsSidebarLayout featureFlagConnectionsRef={queryReference} bottomOptions={bottomOptions} updatesClicked={updatesClicked} handleUpdatesClicked={handleUpdatesClicked} />}
    </Suspense>;
}
interface IMenuOptionsSidebarLayout {
  featureFlagConnectionsRef: PreloadedQuery<MenuOptionsSidebar_Query>;
  bottomOptions: ISideMenuOption[];
  updatesClicked: boolean;
  handleUpdatesClicked: () => void;
}
function MenuOptionsSidebarLayout({
  featureFlagConnectionsRef,
  bottomOptions,
  updatesClicked,
  handleUpdatesClicked
}: IMenuOptionsSidebarLayout): JSX.Element {
  // We want access to URL parameters for overriding bandit subject key or assignment
  const [updatesHighlightStrategy, setUpdatesHighlightStrategy] = useState('');
  const isAudiencesEnabled = useStringFlagOn(EFeatureFlag.FFAudiences);
  const isHoldoutsEnabled = useStringFlagOn(EFeatureFlag.HoldoutsEndToEnd);
  const {
    companyEnabledFeatures,
    userCompany,
    experimentationGettingStartedState,
    randomizationGettingStartedState
  } = usePreloadedQuery<MenuOptionsSidebar_Query>(QUERY, featureFlagConnectionsRef);
  const {
    canUpdateDefinition,
    canReadAdmin,
    canCreateDatabaseConnection,
    canCreateDefinition,
    canCreateMetric,
    canCreateExperiment,
    canCreateTeam,
    canCreateSdkKey,
    canCreateFeatureFlag,
    canUpdateFeatureFlag
  } = useRbacPermissions();
  const isTeamsEnabled = useStringFlagOn(EFeatureFlag.UserTeams);
  const isGettingStartedEnabled = useMemo(() => userCompany.isEppoOwned || (canCreateDatabaseConnection || canCreateDefinition || canCreateMetric || canCreateExperiment || canCreateTeam || canCreateSdkKey || canCreateFeatureFlag || canUpdateFeatureFlag) && !(isCompleted(experimentationGettingStartedState) && isCompleted(randomizationGettingStartedState)), [userCompany.isEppoOwned, canCreateDatabaseConnection, canCreateDefinition, canCreateMetric, canCreateExperiment, canCreateTeam, canCreateSdkKey, canCreateFeatureFlag, canUpdateFeatureFlag, experimentationGettingStartedState, randomizationGettingStartedState]);
  const sidebarBandit = usePrecomputedBanditAction(DOGFOOD_BANDIT_KEY, 'default');
  useEffect(() => {
    if (sidebarBandit.action) {
      setUpdatesHighlightStrategy(sidebarBandit.action);
    }
  }, [sidebarBandit.action]);
  const isInsightsEnabled = useBoolFlagAssignment(EFeatureFlag.KnowledgeBase);
  const isPipelineVisibilityEnabled = useBoolFlagAssignment(EFeatureFlag.PipelineVisibilityWarehouseLeftNav);
  const isNavigationImprovementsEnabled = useBoolFlagAssignment(EFeatureFlag.NavigationImprovements);
  const {
    canReadProtocols
  } = useRbacPermissions();
  const isProtocolsEnabled = useBoolFlagAssignment(EFeatureFlag.ProtocolsMilestoneOne) && canReadProtocols;
  const menuOptions = getMenuOptions({
    companyEnabledFeatures,
    isAdminEnabled: canReadAdmin,
    isTeamsEnabled,
    canUpdateDefinitions: canUpdateDefinition,
    isGettingStartedEnabled,
    isInsightsEnabled,
    isPipelineVisibilityEnabled,
    isHoldoutsEnabled,
    isAudiencesEnabled,
    isNavigationImprovementsEnabled,
    isProtocolsEnabled
  });
  return <Sidebar options={menuOptions} bottomOptions={bottomOptions} updatesHighlightStrategy={updatesClicked ? undefined : updatesHighlightStrategy} updatesClickHandler={handleUpdatesClicked} />;
}
function banditSubjectKey(override: string | string[] | undefined, userId: number): string {
  if (Array.isArray(override)) {
    override = override[0]; // if multiple subjectKey URL params, take the first one
  }
  return override ?? userId + (isProductionEnv() ? '' : '-' + getEnvironment());
}