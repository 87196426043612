import { IUserContext } from './user-context';

export function augmentSubjectAttributes(
  subjectAttributes: Record<string, string | number>,
  userData: IUserContext,
) {
  subjectAttributes['userId'] = userData.userId;
  subjectAttributes['companyId'] = userData.currentCompany.companyId;
  subjectAttributes['email'] = userData.email;
  subjectAttributes['environment'] = process.env.NEXT_PUBLIC_APP_ENV || ''; // need this until we release multiple environments

  return userData.userId;
}

export function augmentSubjectContext(
  subjectContext: {
    numericAttributes: Record<string, number>;
    categoricalAttributes: Record<string, string>;
  },
  userData: IUserContext,
) {
  subjectContext.numericAttributes['userId'] = userData.userId;
  subjectContext.numericAttributes['companyId'] = userData.currentCompany.companyId;
  subjectContext.categoricalAttributes['email'] = userData.email;
  subjectContext.categoricalAttributes['environment'] = process.env.NEXT_PUBLIC_APP_ENV || ''; // need this until we release multiple environments

  return userData.userId;
}
