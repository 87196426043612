import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { COLOR_SUCCESS } from 'constants/colors';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { borderRadius, boxShadow, colors, fontSize, fontWeight, spacing } from 'theme';
import { FlexBox } from 'components/style-components/FlexBox';
import { UnstyledButton } from 'components/ui/buttons/UnstyledButton';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'components/ui/icon/icon-map';
import { Box } from 'components/ui/layout/Box';
import Text from 'components/ui/typography/Text';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import { zIndexTooltip } from '../../../styles/z-index';
import { ISideMenuOption } from './Sidebar';
import { sidebarExpandedWidth } from './styled';
const icons: Record<string, IconName> = {
  Admin: 'Tune.Vertical',
  Analysis: 'Experiment',
  Configuration: 'Feature flag',
  Flags: 'Feature flag',
  Definitions: 'Definitions',
  Docs: 'Help.Unfilled',
  'Getting Started': 'List',
  Insights: 'Idea',
  Metrics: 'Metric',
  Teams: 'Team',
  Warehouse: 'Database.Unfilled',
  Updates: 'Speaker'
};
const COLOR_BACKGROUND_SECONDARY_TRANSPARENT = 'rgba(242, 240, 237, 0.16)';
interface ISidebarOption extends ISideMenuOption {
  highlightStrategy?: string;
  additionalClickHandler?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  setIsHovered: (option: boolean) => void;
  isHovered: boolean;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  selectedParent?: string;
  setSelectedParent: (option?: string) => void;
}
const SidebarOption = ({
  name,
  url,
  forceNewTab: newTab,
  highlightStrategy,
  additionalClickHandler,
  subOptions,
  setIsHovered,
  isHovered,
  selectedOption,
  setSelectedOption,
  selectedParent,
  setSelectedParent
}: ISidebarOption) => {
  const {
    collapsed
  } = useContext(NavCollapsedContext);
  const [subOptionsHidden, setSubOptionsHidden] = useState(true);
  useEffect(() => {
    if (!collapsed) {
      return;
    }
    if (isHovered && !!subOptions) {
      setSubOptionsHidden(false);
      return;
    }
    setSubOptionsHidden(true);
  }, [collapsed, subOptions, isHovered]);
  const router = useRouter();
  const isOptionActive = useCallback((url: string): boolean => {
    if (!url) {
      return false;
    }
    if (selectedParent) {
      return selectedParent.startsWith(url);
    }
    if (selectedOption) {
      return selectedOption.startsWith(url);
    }
    return router.pathname.startsWith(url);
  }, [router.pathname, selectedOption, selectedParent]);
  useEffect(() => {
    if (collapsed) {
      setSubOptionsHidden(true);
      return;
    }
    setSubOptionsHidden(selectedParent !== url);
  }, [selectedParent, url, collapsed]);
  const onOptionClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (additionalClickHandler) {
      additionalClickHandler(e);
    }
    if (!subOptions) {
      setSelectedOption(url);
      setSelectedParent(undefined);
      return;
    }
    setSelectedParent(url);
  };
  const shouldShowCaret = !!subOptions && !collapsed || !!subOptions && collapsed && isHovered;

  // need to check if a suboption is active on initial page load
  const checkedSubOptionsActive = useRef(false);
  useEffect(() => {
    if (checkedSubOptionsActive.current) {
      return;
    }
    subOptions?.forEach(subOption => {
      if (isOptionActive(subOption.url)) {
        setSelectedOption(subOption.url);
        setSelectedParent(subOption.parentUrl);
      }
    });
    checkedSubOptionsActive.current = true;
  }, [subOptions, isOptionActive, setSelectedOption, setSelectedParent]);

  // Temporarily, as part of dogfooding a multi-armed bandit, we have a few different ways to
  // "highlight" that there are new updates. Based on highlightStrategy, we will decorate the name
  // of this sidebar option.
  const emojiDecorator = highlightStrategy === 'emojiSparkle' ? '✨' : null;
  const backgroundColor = highlightStrategy === 'greenBackground' ? COLOR_SUCCESS : undefined;
  const color = highlightStrategy === 'greenText' ? COLOR_SUCCESS : undefined;
  const dot = highlightStrategy === 'greenDot' ? <span style={{
    color: COLOR_SUCCESS
  }}> ⬤</span> : null;
  return <Box css={{
    height: collapsed ? '32px' : 'auto',
    position: 'relative'
  }}>
      <Wrapper collapsed={collapsed} isHovered={isHovered} onMouseOver={() => collapsed && setIsHovered(true)} onFocus={() => collapsed && setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onBlur={() => setIsHovered(false)}>
        <FlexBox alignItems="center" gap={spacing.sm} padding={`0 ${subOptions ? spacing.xs : '0'} 0 0`}>
          <StyledOption href={url} target={newTab ? '_blank' : '_self'} onClick={onOptionClick} color={color} backgroundColor={backgroundColor} collapsed={collapsed} isActive={subOptionsHidden ? isOptionActive(url) : false} isParentHovered={isHovered}>
            {icons[name] && <Icon name={icons[name]} />}
            <Text color="inherit" bold={subOptionsHidden ? isOptionActive(url) : false} ellipsis css={{
            display: collapsed && !isHovered ? 'none' : 'block'
          }}>
              {emojiDecorator}
              {name}
              {dot}
              {emojiDecorator}
            </Text>
          </StyledOption>
          <CaretButton css={{
          display: (subOptions?.length ?? 0 > 0) && !collapsed ? 'block' : 'none',
          maxHeight: shouldShowCaret ? '50px' : '0px',
          transform: !subOptionsHidden ? 'rotate(180deg)' : 'none'
        }} onClick={() => setSubOptionsHidden(h => !h)} collapsed={collapsed}>
            <Icon name="Caret.Down" css={{
            maxHeight: shouldShowCaret ? '50px' : '0px'
          }} />
          </CaretButton>
        </FlexBox>

        <SubOptionsWrapper showOptions={!subOptionsHidden} disableTransition={collapsed}>
          <FlexBox flexDirection="column" css={{
          borderLeft: `1px solid ${colors.border.primary}`
        }}>
            {subOptions?.map(({
            url,
            name,
            parentUrl
          }) => <SideBarSubOption key={`[link-for]${name}:${url}`} href={url} isActive={selectedOption?.startsWith(url)} isParentHovered={isHovered} onClick={() => setSelectedParent(parentUrl)}>
                {name}
              </SideBarSubOption>)}
          </FlexBox>
        </SubOptionsWrapper>
      </Wrapper>
    </Box>;
};
export default SidebarOption;
const Wrapper = styled.div<{
  collapsed?: boolean;
  isHovered?: boolean;
}>(({
  collapsed,
  isHovered
}) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: borderRadius.md,
  ':hover': !isHovered ? {
    width: `${sidebarExpandedWidth - 32}px`,
    zIndex: collapsed ? zIndexTooltip + 1 : undefined,
    boxShadow: collapsed ? boxShadow.default : undefined
  } : undefined
}), ({
  collapsed,
  isHovered = false
}) => isHovered && {
  position: 'absolute',
  width: `${sidebarExpandedWidth - 32}px`,
  zIndex: zIndexTooltip + 1,
  boxShadow: boxShadow.default,
  backgroundColor: collapsed ? colors.surface.white : 'rgba(242, 240, 237, 0.16)'
});
const StyledOption = styled(Link, {
  shouldForwardProp: prop => !(prop.toString().startsWith('is') || ['as', 'backgroundColor', 'collapsed'].includes(prop.toString()))
})<{
  color?: string;
  backgroundColor?: string;
  collapsed?: boolean;
  isActive?: boolean;
  isParentHovered?: boolean;
}>(({
  color,
  backgroundColor,
  collapsed,
  isActive = false,
  isParentHovered = false
}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm,
  padding: spacing.xs,
  flex: 1,
  color: color ?? (isActive ? colors.text.primary : colors.text.white),
  cursor: 'pointer',
  fontWeight: isActive ? '500' : undefined,
  borderRadius: borderRadius.md,
  backgroundColor: backgroundColor ?? (isActive ? colors.background.secondary : undefined),
  ':hover': {
    ...(isActive ? {
      color: colors.text.primary
    } : {
      color: collapsed ? colors.text.primary : colors.text.white,
      backgroundColor: collapsed ? colors.surface.white : 'rgba(242, 240, 237, 0.16)'
    })
  },
  ...(isParentHovered && {
    color: colors.text.primary
  })
}));
const CaretButton = styled(UnstyledButton)<{
  isActive?: boolean;
  collapsed?: boolean;
}>(({
  collapsed
}) => ({
  flex: 0,
  transition: collapsed ? undefined : 'all 0.3s ease-in-out',
  borderRadius: borderRadius.md,
  ':hover': {
    color: collapsed ? colors.text.primary : colors.text.white,
    backgroundColor: collapsed ? colors.surface.white : 'rgba(242, 240, 237, 0.16)'
  }
}));
const SubOptionsWrapper = styled.div<{
  showOptions?: boolean;
  disableTransition?: boolean;
}>(({
  showOptions = true,
  disableTransition = false
}) => ({
  padding: !showOptions ? 0 : `${spacing.xs} 0 ${spacing.xs} ${spacing.lg}`,
  maxHeight: !showOptions ? 0 : '500px',
  overflow: 'hidden',
  transition: disableTransition ? undefined : 'all 0.3s ease-in-out'
}));
const SideBarSubOption = styled(Link, {
  shouldForwardProp: prop => !prop.toString().startsWith('is')
})<{
  isActive?: boolean;
  isParentHovered?: boolean;
}>(({
  isActive = false,
  isParentHovered = false
}) => ({
  padding: `${spacing.xs} 0 ${spacing.xs} 20px`,
  color: isParentHovered ? colors.text.primary : colors.text.white,
  fontWeight: fontWeight.regular,
  fontSize: fontSize.body,
  '&:hover': isParentHovered ? {
    color: colors.text.primary,
    backgroundColor: colors.background.secondary
  } : {
    color: colors.text.white,
    backgroundColor: COLOR_BACKGROUND_SECONDARY_TRANSPARENT
  },
  ...(isActive && {
    color: colors.text.primary,
    fontWeight: fontWeight.medium,
    backgroundColor: colors.background.secondary
  })
}));