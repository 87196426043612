import { EFeatureFlag } from 'constants/featureFlags';

import { getInstance, getPrecomputedInstance } from '@eppo/js-client-sdk';

import { augmentSubjectAttributes } from 'lib/augment-subject-attributes';

import { useUser } from 'hooks/useUser';

// Please check with FF team before modifying.
export const shouldInitializePrecomputedClient = false;
const shouldLogDebugPrecomputedClient = true;

/**
 * This method is meant to pull non-randomized feature flag configurations.
 * It randomizes based on userId, so if a flag has randomization, different users within the same company may get different results.
 */
export function useBoolFlagAssignment(
  flagKey: EFeatureFlag,
  subjectAttributes = {} as Record<string, string | number>,
  defaultValue = false,
): boolean {
  const userId = augmentSubjectAttributes(subjectAttributes, useUser());
  const subjectKey = String(userId);

  const eppoClient = getInstance();
  // :troll:
  const legacyAssignment = eppoClient.getBooleanAssignment(
    flagKey,
    subjectKey,
    subjectAttributes,
    defaultValue,
  );

  const eppoPrecomputedClient = getPrecomputedInstance();
  if (shouldInitializePrecomputedClient && eppoPrecomputedClient) {
    const precomputedAssignment = eppoPrecomputedClient.getBooleanAssignment(flagKey, defaultValue);

    if (shouldLogDebugPrecomputedClient) {
      console.debug('useBoolFlagAssignment', {
        flagKey,
        precomputedAssignment,
        legacyAssignment,
      });
    }
  }

  return legacyAssignment;
}

/**
 * This method is meant to pull non-randomized feature flag configurations.
 * It randomizes based on userId, so if a flag has randomization, different users within the same company may get different results.
 */
export function useStringFlagAssignment(
  flagKey: EFeatureFlag,
  subjectAttributes: Record<string, string | number>,
  defaultValue: string,
): string {
  const userId = augmentSubjectAttributes(subjectAttributes, useUser());
  const subjectKey = String(userId);

  const eppoClient = getInstance();
  const legacyAssignment = eppoClient.getStringAssignment(
    flagKey,
    subjectKey,
    subjectAttributes,
    defaultValue,
  );

  const eppoPrecomputedClient = getPrecomputedInstance();
  if (shouldInitializePrecomputedClient && eppoPrecomputedClient) {
    const precomputedAssignment = eppoPrecomputedClient.getStringAssignment(flagKey, defaultValue);

    if (shouldLogDebugPrecomputedClient) {
      console.debug('useStringFlagAssignment', {
        flagKey,
        precomputedAssignment,
        legacyAssignment,
      });
    }
  }

  return legacyAssignment;
}

/**
 * Shortcut for checking legacy "ON/OFF" feature flags (string-valued flags used as boolean switches).
 *
 * @deprecated Use useBoolFlagAssignment instead, together with a boolean-typed flag.
 * This method is only to be used to support legacy string-valued flags.
 */
export function useStringFlagOn(
  flagKey: EFeatureFlag,
  subjectAttributes: Record<string, string | number> = {},
): boolean {
  const assignment = useStringFlagAssignment(flagKey, subjectAttributes, 'off');
  return ['on', 'true'].includes(assignment.toString().toLowerCase());
}

export function useIntFlagAssignment(
  flagKey: EFeatureFlag,
  subjectAttributes: Record<string, string | number>,
  defaultValue: number,
): number {
  const userId = augmentSubjectAttributes(subjectAttributes, useUser());
  const subjectKey = String(userId);

  const eppoClient = getInstance();
  const legacyAssignment = eppoClient.getIntegerAssignment(
    flagKey,
    subjectKey,
    subjectAttributes,
    defaultValue,
  );

  const eppoPrecomputedClient = getPrecomputedInstance();
  if (shouldInitializePrecomputedClient && eppoPrecomputedClient) {
    const precomputedAssignment = eppoPrecomputedClient.getIntegerAssignment(flagKey, defaultValue);

    if (shouldLogDebugPrecomputedClient) {
      console.debug('useIntFlagAssignment', {
        flagKey,
        precomputedAssignment,
        legacyAssignment,
      });
    }
  }

  return legacyAssignment;
}

export function usePrecomputedBanditAction(
  flagKey: string,
  defaultValue: string,
): { variation: string; action: string | null } {
  const eppoPrecomputedClient = getPrecomputedInstance();
  const precomputedAssignment = eppoPrecomputedClient.getBanditAction(flagKey, defaultValue);

  if (shouldLogDebugPrecomputedClient) {
    console.debug('usePrecomputedBanditAction', {
      flagKey,
      precomputedAssignment,
    });
  }

  return precomputedAssignment;
}
