/**
 * @generated SignedSource<<e733b5d8035330bb7af175883435da0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileTeamsTable_Fragment$data = ReadonlyArray<{
  readonly experimentCount: number;
  readonly icon: string;
  readonly id: string;
  readonly metricCount: number;
  readonly name: string;
  readonly " $fragmentType": "UserProfileTeamsTable_Fragment";
}>;
export type UserProfileTeamsTable_Fragment$key = ReadonlyArray<{
  readonly " $data"?: UserProfileTeamsTable_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileTeamsTable_Fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserProfileTeamsTable_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "experimentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metricCount",
      "storageKey": null
    }
  ],
  "type": "Team",
  "abstractKey": null
};

(node as any).hash = "8c68e0a982469bdd274d786f6f23a470";

export default node;
